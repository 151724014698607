.list-item {
    padding: 1.5rem 2.25rem 1.5rem 2.5rem;
    font-weight: 500;
    color: #070707;
    background-color: white;
    &:nth-child(odd) {
        background-color: #f4f4f4;
    }
    &--light-text {
        font-weight: 400;
        font-size: 0.8125rem;
    }
    &--center-v {
        display: flex;
        flex-direction: column;
        justify-content: center;
        & > * {
            flex: 1;
        }
        & > .rate {
            flex: 0;
        }
    }
}