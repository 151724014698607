.sortable-section {
    &__navigation {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-bottom: 1rem;
        &__sort {
            display: flex;
            align-items: center;
            color: $base-color;
            font-size: 1rem;
            line-height: 2rem;
            transition: opacity 0.5s;
            opacity: 1;
            cursor: pointer;
            &:hover {
                opacity: 0.75;
            }
            i {
                padding-left: 0.5rem;
                color: $success-color;
                font-size: 1.6875rem;
            }
            &--up {
                border-right: solid thin $base-color;
                margin-right: 1rem;
                padding-right: 0.5rem;
            }
        }
    }
    &__content {
        border: 3px dashed #d0d0d0;
        padding: 1.375rem;
    }
    &__title {
        font-size: 1.5rem;
        color: $base-color;
    }
}