div.filter-form {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    & > div {
        flex: 1;
        label {
            float: left;
        }
        input, select {
            float: left;
            margin-bottom: 0px;
        }
        .btn {
            height: 38px;
        }
    }
}