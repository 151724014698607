.btn-add-day {
    min-width: 250px;
    padding: 0.5rem 0.25rem;
    &--template {
        position: relative;
        margin-top: 1rem;
        &::before {
            content: 'OU';
            font-size: 12px;
            color: #adb5bd;
            position: absolute;
            top: -1.25rem;
            left: 45%;
        }
    }
    &:focus, &:hover {
        color: white;
    }
}