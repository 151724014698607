.advanced-table {
    $base: &;
    overflow-x: scroll;
    & > * {
        flex-wrap: nowrap;
    }
    &__header {
        height: 6rem;
        font-size: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-weight: 500;
        &__title {
            color: $base-color;
        }
        &__subtitle {
            text-transform: uppercase;
            color: $secondary-color;
        }
    }
    &__item {
        text-align: center;
        margin-right: 1rem;
        &--center-col {
            #{$base}__cell {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
            }
        }
        &--left-col {
            margin-right: 0rem;
            text-align: left;
            #{$base}__cell {
                background-color: transparent;
            }
        }
    }
    &__cell {
        background-color: white;
        &__left-title {
            color: $secondary-color;
            text-transform: uppercase;
            font-size: 1.375rem;
            font-weight: 500;
        }
        &__left-subtitle {
            color: $base-color;
            font-size: 0.8125rem;
        }
        &__large-text {
            color: #404040;
            font-size: 1.5625rem;
            font-weight: 500;
        }
        &--force-padding {
            padding-left: 2rem;
            padding-right: 1rem;
            padding-top: 1.5rem;
        }

        .accordion {
            flex: 1;
        }
    }
    &--fixed-width {
        #{$base}__item {
            width: 19rem;
        }
    }

    // fixed heights
    .fh {
        &-sm {

        }
        &-md {
            height: 5.625rem !important;
        }
        &-lg {
            height: 9.5rem;
        }
    }
}