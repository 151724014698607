.accordion {
    &__item {
        padding-bottom: 5px;
        &__header {
            background-color: $green-color;
            padding: 1rem 2.25rem;
            color: white;
            cursor: pointer;
            font-weight: 500;
            &__title {
                $title: &;
                position: relative;
                display: inline-block;
                min-width: 12rem;
                &::after {
                    content: '>';
                    position: absolute;
                    right: -1.5rem;
                    display: inline-block;
                    transform: rotate(90deg);
                    transition: all 0.25s;
                }
            }
            &.collapsed {
                .accordion__item__header__title {
                    &::after {
                        content: '>';
                        transform: rotate(0deg);
                    }
                } 
            }
        }
    }
}