.rate {
    $base: &;
    flex: 1;
    padding: 0px 1.875rem;
    &__bar {
        box-shadow:inset 0px 0px 0px 1px #707070;
        border-radius: 25px;
        height: 0.875rem;
        &__progress {
            height: 100%;
            border-radius: 25px;
            background-color: $warning-color;
            &--success {
                background-color: $green-color;
            }
            &--warning {
                background-color: $warning-color;
            }
            &--danger {
                background-color: $danger-color;
            }
        }
    }
    &__value {
        color: $base-color;
        font-size: 1.8125rem;
        small {
            font-size: 1.2rem;
        }
    }
    &--medium {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        padding: 0px;
        #{$base}__bar {
            flex: 1;
            height: 0.5rem;
        }
        #{$base}__value {
            flex: 0;
            font-size: 1.25rem;
            padding-left: 0.5rem;
            small {
                font-size: 0.8125rem;
            }
        }
    }
}