body {
    background-color: #f2f7f8;
}

b, strong {
    font-weight: 500;
}

.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem;
}

.form-control {
    margin-bottom: 15px;
}
.fa {
    margin-right : 10px;
}
button.btn.btn-danger.btn-rounded.btn-submit {
    color:#fff !important;
}
button.btn.btn-success.btn-rounded.btn-submit {
    min-width: 200px;
    height:40px;
    margin-top:15px;
    &.btn-lg {
        height: unset;
    }
}

.btn-link {
    &--danger {
        color: red !important;
    }
    &--disabled {
        color: grey !important;
        cursor: default;
    }
}

.multi-wrapper {
    margin-bottom: 30px;
}
.days-title-label {
    line-height: 36px;
    font-size: 30px;
    margin-top:20px;
}

.day_nb_actions label {
    margin-right: 2.1875rem;
    margin-left: 0.5rem;
}

.videos-title-label {
    line-height: 36px;
    font-size: 24px;
    margin-top:20px;
    padding-left:15px;
}

.simpleTable th {
    text-decoration: underline !important;
}
  
.hidden {
    display: none;
}

.hide-labels label {
    display: none;
}

.left-sidebar {
    max-height: 100vh;
    overflow-y: auto;
}

.pagination {
    & > .active > a {
        color: white;
        &:hover {
            color: white;
        }
    }
}
